import style from './style.css';

const CheckboxWithText = ({ text1, text2, selected, action, otherStyle }) => {

  return (
    <div className={`${style.switchContainer} ${otherStyle ? otherStyle : ''}`}>
      <span className={`${style.selectionLayer}`} style={{ left: selected == 1 ? '8px' : '93px' }} />
      <button
        className={ selected == 1 ? style.selectedBtn : style.notSelectedBtn }
        onClick={action}
      >{text1}</button>
      <button
        className={ selected != 1 ? style.selectedBtn : style.notSelectedBtn }
        onClick={action}
      >{text2}</button>
    </div>
  );
}
 
export default CheckboxWithText;