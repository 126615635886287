import { convertDateToUTCPlusOffset } from "@utils/date";
import {isBleeding} from '@components/Event'

export const errorCodes = {
  // Number formatting errors
  LEADING_OR_TRAILING_SPACE: 'LEADING_OR_TRAILING_SPACE',
  MULTIPLE_SPACES_BETWEEN_TOKENS: 'MULTIPLE_SPACES_BETWEEN_TOKENS',
  NONSENSICAL_DURATION: 'NONSENSICAL_DURATION',
  UNREASONABLE_DURATION: 'UNREASONABLE_DURATION',

  // Token errors
  NUMBER_BEFORE_PS: 'NUMBER_BEFORE_PS',
  NUMBER_BEFORE_BB: 'NUMBER_BEFORE_BB',
  MISSING_DURATION: 'MISSING_DURATION',
  INVALID_TOKEN: 'INVALID_TOKEN',
  CONSECUTIVE_SAME_TOKENS: 'CONSECUTIVE_SAME_TOKENS',

  // Sequence errors
  MULTIPLE_PS_WITHOUT_BB: 'MULTIPLE_PS_WITHOUT_BB',
  BB_WITHOUT_PS: 'BB_WITHOUT_PS',
  INSUFFICIENT_TIME_FOR_BB: 'INSUFFICIENT_TIME_FOR_BB',
  EXTRA_PS_WITHIN_PS_BB_SUBSEQUENCE: 'EXTRA_PS_WITHIN_PS_BB_SUBSEQUENCE',
};

const preValidation = (sequence) => {
  const invalidCharMatch = sequence.match(/[^B+T PS\d]/);
  if (invalidCharMatch) {
    return [false, errorCodes.INVALID_TOKEN, invalidCharMatch[0]];
  }

  // Detect any sequence that doesn't fit the categories of PS, BB, or a number followed by B/T
  if (/[0-9]+PS/.test(sequence)) {
    return [false, errorCodes.NUMBER_BEFORE_PS, sequence.match(/[0-9]+PS/)[0]];
  }

  if (/[0-9]+BB/.test(sequence)) {
    return [false, errorCodes.NUMBER_BEFORE_BB, sequence.match(/[0-9]+BB/)[0]];
  }

  if (/^\s/.test(sequence) || /\s$/.test(sequence)) {
    return [false, errorCodes.LEADING_OR_TRAILING_SPACE, null];
  }

  if (/[ ]{2,}/.test(sequence)) {
    return [false, errorCodes.MULTIPLE_SPACES_BETWEEN_TOKENS, null];
  }

  if (/\b(?<![0-9])(B|T)\b(?!B)/.test(sequence)) {
    return [false, errorCodes.MISSING_DURATION, sequence.match(/\b(?<![0-9])(B|T)\b(?!B)/)[0]];
  }

  // Detect nonsensical durations (leading zeroes)
  const nonsensicalDurationRegex = /\b(0+\d+|0)(B|T)/;
  let match = sequence.match(nonsensicalDurationRegex);
  if (match) {
    return [false, errorCodes.NONSENSICAL_DURATION, match[0]];
  }

  // Detect unreasonable durations (1000 or more)
  const unreasonableDurationRegex = /\d{4,}(B|T)/;
  match = sequence.match(unreasonableDurationRegex);
  if (match) {
    return [false, errorCodes.UNREASONABLE_DURATION, match[0]];
  }

  const invalidTokenRegex = /\b(?!PS|BB|\d+(B|T)\b)\S+/;
  match = sequence.replace(/(?<!B)B\+/g, "B").match(invalidTokenRegex);
  if (match) {
    return [false, errorCodes.INVALID_TOKEN, match[0]];
  }

  return [true, null, null];
}

export const ps2bbValidations = (sequence) => {
  // Extract all PS ... BB sequences
  const psToBbRegex = /PS(.*?)BB/g;
  let match;
  let subsequences = [];
  let remnant = ""

  while ((match = psToBbRegex.exec(sequence)) !== null) {
    subsequences.push(match[1].trim());
    remnant = sequence.substring(match.index + match[0].length).trim();
  }

  if (subsequences.length > 0) {
    for (const subsequence of subsequences) {
      // Handle edge case of PS BB
      if (subsequence === '') {
        return [false, errorCodes.INSUFFICIENT_TIME_FOR_BB, 'BB'];
      }

      // Ensure there's no PS in between
      if (subsequence.includes("PS")) {
        return [false, errorCodes.EXTRA_PS_WITHIN_PS_BB_SUBSEQUENCE, "PS"];
      }

      // Check for BB without PS
      if (subsequence.includes("BB")) {
        return [false, errorCodes.BB_WITHOUT_PS, "BB"];
      }

      // Calculate total time duration in the subsequence
      const tokens = subsequence.split(' ');
      let totalDuration = 0;
      let previousUnit = null;

      for (const token of tokens) {
        const timeMatch = token.match(/^(\d+)(B|T)$/);
        if (!timeMatch) {
          return [false, errorCodes.MISSING_DURATION, token];
        }

        const [, durationStr, unit] = timeMatch;
        if (unit === previousUnit) {
          return [false, errorCodes.CONSECUTIVE_SAME_TOKENS, token];
        }
        previousUnit = unit;

        const duration = parseInt(durationStr, 10);
        totalDuration += duration
      }

      if (totalDuration < 180) {
        return [false, errorCodes.INSUFFICIENT_TIME_FOR_BB, subsequence];
      }
    }
  } else {
    // Ensure there is only a single PS at most
    const psOccurrences = (sequence.match(/PS/g) || []).length;
    if (psOccurrences > 1) {
      return [false, errorCodes.MULTIPLE_PS_WITHOUT_BB, "PS"];
    }

    // No BB allowed
    if (sequence.includes("BB")) {
      return [false, errorCodes.BB_WITHOUT_PS, "BB"];
    }
  }

  if (remnant && remnant !== sequence) {
    // No BB allowed
    if (remnant.includes("BB")) {
      return [false, errorCodes.BB_WITHOUT_PS, "BB"];
    }
  }

  return [true, 'VALID', null];
}

export const isValidSequence = (sequence) => {
  // First, pre-validate the sequence
  const [isPreValid, preErrorCode, preErrorToken] = preValidation(sequence);
  if (!isPreValid) {
    return [isPreValid, preErrorCode, preErrorToken];
  }

  // If the sequence passed pre-validation, proceed to ps2bbValidations
  return ps2bbValidations(sequence);
};

const validate = ({ sequence, hayd_habit, tuhr_habit, nifaas_habit }) => {
  const errors = []
  if (!sequence.trim()) {
    errors.push("Sequence cannot be empty.");
  }

  const validationResult = isValidSequence(sequence);

  if (!validationResult[0]) {
    const errorCode = validationResult[1];
    switch (errorCode) {
      case 'LEADING_OR_TRAILING_SPACE':
          errors.push("Sequences should neither start nor end with a space to ensure clear and unambiguous input.");
          break;
      case 'MULTIPLE_SPACES_BETWEEN_TOKENS':
          errors.push("Multiple spaces between tokens can create confusion. Kindly use a single space for clarity.");
          break;
      case 'MISSING_DURATION':
          errors.push(`The token '${validationResult[2]}' is missing its associated duration. Every B/T event should have a clear timeframe.`);
          break;
      case 'MULTIPLE_PS_WITHOUT_BB':
          errors.push("It's invalid to have multiple 'PS' tokens without a 'BB' in between. 'PS' indicates the start of a pregnancy, and 'BB' denotes a baby's birth. Multiple pregnancies without births in between are not possible.");
          break;
      case 'BB_WITHOUT_PS':
          errors.push("A 'BB' without a preceding 'PS' is incomplete. A baby's birth ('BB') cannot occur without a prior pregnancy ('PS').");
          break;
      case 'NUMBER_BEFORE_BB':
          errors.push("A duration before 'BB' is not permissible. 'BB' marks the moment a baby is born, which is an instantaneous event without a duration.");
          break;
      case 'NUMBER_BEFORE_PS':
          errors.push("'PS' should not be prefixed with a duration. It signifies the onset of a pregnancy, an instantaneous event. If you want to represent the entire pregnancy's duration, use a sequence like PS 280T BB.");
          break;
      case 'INVALID_TOKEN':
          errors.push(`The token '${validationResult[2]}' isn't recognized. Kindly review the Notation Guide for all supported tokens.`);
          break;
      case 'INSUFFICIENT_TIME_FOR_BB':
          errors.push("A minimum of 180 days is expected between 'PS' and 'BB' events.");
          break;
      case 'UNREASONABLE_DURATION':
          errors.push(`The duration specified in '${validationResult[2]}' is out of the permissible range. Durations for B and T events should be between 1 and 999 days.`);
          break;
      case 'NONSENSICAL_DURATION':
          errors.push(`The duration specified as '${validationResult[2]}' is nonsensical. Kindle use valid numerical representations.`);
          break;
      case 'CONSECUTIVE_SAME_TOKENS':
          errors.push(`Consecutive usage of the same token '${validationResult[2]}' is invalid. B/T tokens are to alternate.`);
          break;
      case 'EXTRA_PS_WITHIN_PS_BB_SUBSEQUENCE':
          errors.push("It's invalid to have an extra 'PS'(PregnancyStarted) between 'PS' and 'BB'(BabyBirthed) subsequence.");
          break;
      default:
          errors.push("There seems to be an issue with the sequence syntax. Kindly check and ensure compliance with Notation Guide.");
          break;
    }
  }

  if (hayd_habit < 3 || hayd_habit > 10) {
    errors.push("Hayd habit must be between 3 and 10.");
  }

  if (tuhr_habit < 15 || tuhr_habit > 1000) {
    errors.push("Tuhr habit must be between 15 and 1000.");
  }

  if (nifaas_habit && (nifaas_habit < 0 || nifaas_habit > 40)) {
    errors.push("Nifaas habit must be between 0 and 40.");
  }

  return errors;
}

const areTSsOnSameDay = ({ ts1, ts2 }) => {
  const date1 = new Date( ts1 * 1000 );
  const date2 = new Date( ts2 * 1000 );
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export const validateEvents = (events) => {
  const errors = [];

  if ( events.length === 1 ) {
    const event = events[0];
    if ( isBleeding(event.type) && event.to < event.from ) {
      errors.push(`Event 1 has end date-time before the start date-time`);
    }
  }

  let tryToSortAdvice = false;

  events.reduce((prev, next, index) => {
    if (isBleeding(prev.type) && isBleeding(next.type) && prev.to > next.from) {
      errors.push(`Event ${index} exceeds start date-time of Event ${index + 1}`);
      tryToSortAdvice = true;
    } else if (isBleeding(prev.type) && isBleeding(next.type)) {
      if (next.from - prev.to < oneDayTS) {
        errors.push(`A gap of 24 hours or more is required between two islands of bleedings: Event ${index} and ${index + 1}`);
      }
    }

    if ( isBleeding(prev.type) && prev.to < prev.from ) {
      errors.push(`Event ${index} has end date-time before the start date-time`);
    }
    
    if ( events.length === index+1 && isBleeding(next.type) && next.to < next.from ) {
      errors.push(`Event ${index+1} has end date-time before the start date-time`);
    }

    return next;
  });

  if ( tryToSortAdvice ) {
    errors.push(`Try to click on the sort button to sort the events and try again.`);
  }

  return (errors?.length < 1 ? null : errors);
}

const oneDayTS = 1 * 24 * 60 * 60;

const CalculatorApi = ({ input, setInput, history, setHistory, setError, setSubmit, isValidationEnabled, setShowResults, calculationMode }) => {
  const handleSubmit = async ({body}) => {
    console.log('body', body)
    try {
      const response = await fetch(`${process.env.HAYD_API_URL}/api/calculate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      })

      const data = await response.json();
      console.log('data', data)
      if ( data.error_code ) {
        setSubmit(false);
        setError(data.message);
        return;
      }
      // remove events if the user submits in notation mode ( to prevent loading false data )
      // check if history includes this calculation
      const calculatedItem = { question: calculationMode == 'notation' ? {...input, events: null} : input, answer_breakdown: data.answer_breakdown, sequence: data.sequence, answer: data.answer, habit: data.habit, habit_history: data.habit_history, explanations: data.explanations, timeline: data.timeline };
      const filteredHistory = history.filter((e) => JSON.stringify(e.question) !== JSON.stringify(calculatedItem.question));
      setHistory([calculatedItem, ...filteredHistory]);
      setInput({
        sequence: "",
        hayd_habit: null, // was there any reason to set a value for hayd and tuhr ?
        tuhr_habit: null,
        nifaas_habit: null,
        events: null,
      });
      setShowResults(true);
      setSubmit(false);
    } catch(error) {
      setError('Error: Unable to connect to the API', error);
    } finally {
      setSubmit(false);
    }
  };

  if ( calculationMode == 'notation' ) {
    const body = JSON.stringify({
      habit: {
        hayd: input.hayd_habit,
        tuhr: input.tuhr_habit,
        nifaas: input.nifaas_habit,
      },
      sequence: `${input.sequence} SE`,
      calculationMode: "notational"
    })

    if (isValidationEnabled) {
      const validationResult = validate(input);
      if (validationResult.length > 0) {
        setError(validationResult.join(" "));
      } else {
        handleSubmit({body}).then(() => setSubmit(false));
      }
    } else {
      handleSubmit({body}).then(() => setSubmit(false));
    }
  } else {
    // Calendar mode
    const events = input.events;
    console.log('[CalculatorAPI] events', events)
    if ( events?.length > 0 ) {
      // Validate events
      const errors = validateEvents(events);

      if ( errors ) {
        setError(errors.join('<br/>'));
        return;
      }

      const calendarData = [];
      // We are going to add the days from start to end
      events.forEach((event) => {
        if ( isBleeding(event.type)) {
          calendarData.push({
            colour: "red",
            type: event.type,
            viscosity: "liquid",
            volume: "light",
            ts: convertDateToUTCPlusOffset(event.from)
          });
          if ( !areTSsOnSameDay({ ts1: event.from, ts2: event.to  }) ) {
            let date = event.from + oneDayTS;
            while ( !areTSsOnSameDay({ ts1: date, ts2: event.to }) ) {
              console.log('TS push date', date);
              calendarData.push({
                colour: "red",
                type: event.type,
                viscosity: "liquid",
                volume: "light",
                ts: convertDateToUTCPlusOffset(date)
              });
              date = date + oneDayTS;
            }
          }
          calendarData.push({
            colour: "red",
            type: event.type,
            viscosity: "liquid",
            volume: "light",
            ts: convertDateToUTCPlusOffset(event.to)
          });
        } else if ( event.type === 'pregnancy' ) {
          calendarData.push({
            type: 'PregnancyStartDate',
            ts: convertDateToUTCPlusOffset(event.from)
          });
        } else if ( event.type === 'baby' ) {
          calendarData.push({
            type: 'PregnancyDueDate',
            ts: convertDateToUTCPlusOffset(event.from)
          });
        } else {
          console.log('[CaculatorAPI] error, unkown event type', event);
        }
      });

      console.log('[CalculatorAPI] calendarData before sorting', calendarData);
      if ( calendarData.length === 0 ) {
        setError("Events/Bleedings can't be empty");
      }

      // Sort calendarData
      calendarData.sort((a, b) => a.ts - b.ts);

      const body = JSON.stringify({
        habit: {
          hayd: input.hayd_habit,
          tuhr: input.tuhr_habit,
          nifaas: input.nifaas_habit,
        },
        events: calendarData,
        calculationMode: "calendar"
      })
      handleSubmit({body}).then(() => setSubmit(false));
    } else {
      setError("Events can't be empty");
    }
    // Extra setSubmit that make events data not showing loading
    // setSubmit(false);
  }

  return <></>;
};

export default CalculatorApi;
