import { useState, useEffect } from 'preact/hooks';
import style from './style.css';
import QA from '@components/QA';
import useDeviceType from '@hooks/useDeviceType';

const MainModal = ({ calculationMode, showResults, setShowResults }) => {
  const [bodyHeight, setBodyHeight] = useState(0);
  const device = useDeviceType();

  useEffect(() => {
    setBodyHeight(window.innerHeight);

    const handleResize = () => {
      setBodyHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={style.modal} style={{height: `${bodyHeight - ( ( device == 'mobile') ? 130 : 175 )}px`}}>
      <QA
        calculationMode={calculationMode}
        showResults={showResults}
        setShowResults={setShowResults}
      />
    </div>
  );
}
 
export default MainModal;