import Input from '@components/Input';
import style from './style.css';
import { useEffect, useRef, useState } from 'preact/hooks';
import SequenceIcon from '@assets/icons/text.png';
import CalendarIcon from '@assets/icons/calendar.png';
import CheckboxWithText from '@components/CheckboxWithText';
import { formatDate, formatBreakDownDate } from '@utils/date';
import { Fragment } from 'preact';
import { useLoading } from '@contexts/loading_context';
import { downloadPDF, sharePDF } from './helper';
import Popup from '@components/Popup';
import { Link } from 'preact-router';
import {isBleeding, startingLabel} from '@components/Event'

if (window.mermaid) {
  window.mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    width: '100%',
  });
}

const RenderGraph = ({graphDefinition}) => {
  useEffect(() => {

    const mermaidContainer = document.querySelector('.mermaid');
    if (mermaidContainer) {
      mermaidContainer.innerHTML = '';
      mermaidContainer.textContent = graphDefinition;
      mermaidContainer.removeAttribute('data-processed');
    }

    // Re-initialize Mermaid to render the new graph
    if (window.mermaid) {
      window.mermaid.init(undefined, document.querySelectorAll('.mermaid'));
    }

  }, [graphDefinition]);

  return (
    <div className={`mermaid ${style.mermaidGraph}`} id='graph' />
  )
};

const Answer = ({ history, setInput, setSubmit, setShowResults, setError }) => {
  const answerContainerRef = useRef();
  const [numberOfVisibleHistoryItems, setNumberOfVisibleHistoryItems] = useState(history?.length ? history.length > 3 ? 3 : history.length : 0);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const most_recent_entry = history && history[0] ? history[0] : {timeline: false, question: false}
  const graphDefinition = most_recent_entry.timeline;
  const [ dataMode, setDataMode ] = useState('sequence');
  const [pdfPopup, setPdfPopup ] = useState();
  const [pdfData, setPdfData ] = useState({});
  const [ sharePDFUrl, setSharePDFUrl ] = useState('');
  const setLoading = useLoading();
  const setSelectedEntry = (entry) => {
    setInput({
      sequence: entry.question.sequence,
      // sequence: entry.sequence, // this adds extra SE to the sequenece which then render an error (The token 'E' isn't recognized. Kindly review the Notation Guide for all supported tokens.)
      hayd_habit: entry.question.hayd_habit,
      tuhr_habit: entry.question.tuhr_habit,
      nifaas_habit: entry.question.nifaas_habit,
      events: entry.question?.events,
    });
    setSubmit(true);
    if ( answerContainerRef?.current ) {
      answerContainerRef.current.scroll({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  const handleLoadMore = () => {
    setNumberOfVisibleHistoryItems((prev) => (prev + 3) >= history.length ? history.length : prev+3 );
  };

  const getColor = (txt) => {
    if (!txt) {
      console.log('Error, can not get color of undefined text', txt);
      return;
    }
    const rulings = ['OngoingHayd', 'Hayd', 'Tuhr', 'Nifaas', 'BleedingMostLikelyIstihadah', 'BleedingMostLikelyHayd', 'Pregnancy'];

    if ( rulings.includes(txt) ) {
      switch(txt){
        case 'OngoingHayd':
        case 'Hayd':
        case 'BleedingMostLikelyHayd':
          return style.red;
        case 'Tuhr':
          return style.blue;
        case 'Nifaas':
          return style.pink;
        case 'BabyBirthed':
          return style.purple;
        case 'PreegnancyStarted':
          return style.green;
        case 'BleedingMostLikelyIstihadah':
          return style.yellowBrown;
      }
    }

    const chars = txt.split('');
    const beforeLastChar = chars[chars.length - 2];
    const lastChar = chars[chars.length - 1];

    if ( isNaN(beforeLastChar) ) {
      if ( beforeLastChar == 'B' ) {
        return style.purple;
      } else if ( beforeLastChar == 'i' && lastChar == ')' ) {
        return style.dullRed;
      } else if ( beforeLastChar == 'h' && lastChar == ')' ) {
        return style.red;
      }
    }

    switch(lastChar){
      case 'B':
      case 'H':
        return style.red;
      case 'T':
        return style.blue;
      case 'S':
        return style.green;
      case 'U':
        return style.yellow;
      case 'N':
        return style.pink;
    }
  };

  return (
    <div className={style.answerContainer} ref={answerContainerRef}>
      {most_recent_entry.question && <div>
        <div className={`${style.section} ${style.calcWithSameDataSection}`}>
          { most_recent_entry?.question?.events &&  <button className={style.calcWithSameDataBtn} onClick={() => {
           setInput({
              sequence: most_recent_entry.question.sequence,
              hayd_habit: most_recent_entry.question.hayd_habit,
              tuhr_habit: most_recent_entry.question.tuhr_habit,
              nifaas_habit: most_recent_entry.question.nifaas_habit,
              events: most_recent_entry.question.events,
            });
            setShowResults(false);
          }}>Calculate again with same data</button> }
          <button className={style.loadMoreBtn} onClick={() => setPdfPopup(true)}>Export PDF</button>
        </div>
        {(pdfPopup && !generatingPDF) && <Popup
          title='Export PDF'
          component={<div className={style.shareModal}>
            <Input
              label='Title'
              value={pdfData?.title || ''}
              placeholder={'Title'}
              type='text'
              action={(e) => {
                setPdfData((oldState) => {
                  oldState['title'] = e.target.value;
                  return {...oldState};
                })
              }}
              otherStyle={style.input}
            />
            <Input
              label='Fatwa URL'
              value={pdfData?.fatwa || ''}
              placeholder={'Fatwa URL'}
              type='text'
              action={(e) => {
                setPdfData((oldState) => {
                  oldState['fatwa'] = e.target.value;
                  return {...oldState};
                })
              }}
              otherStyle={style.input}
            />
            <CheckboxWithText
              text1={'Accepted'}
              text2={'Rejected'}
              selected={!pdfData?.accepted ? 2 : 1}
              action={() => {
                setPdfData((oldState) => {
                  oldState['accepted'] = !pdfData?.accepted;
                  return {...oldState};
                })
              }}
              otherStyle={style.checkboxWithText}
            />
            <Input
              label='Note'
              value={pdfData?.note || ''}
              placeholder={'Note'}
              type='text'
              action={(e) => {
                setPdfData((oldState) => {
                  oldState['note'] = e.target.value;
                  return {...oldState};
                })
              }}
              otherStyle={style.input}
            />
            <div className={style.pdfPopupBtnsContainer}>
              <button className={style.loadMoreBtn} onClick={() => downloadPDF({ setGeneratingPDF, pdfData, setLoading, setError })}>Download PDF</button>
              <button style={{display: 'none'}} className={style.loadMoreBtn} onClick={() => sharePDF({ setGeneratingPDF, pdfData, setLoading, setError, onSuccess: (url) => {
                setSharePDFUrl(url);
              } })}>Share PDF</button>
              <button className={style.loadMoreBtn} onClick={() => {
                setPdfPopup(false);
                setPdfData({});
              }}>Close</button>
            </div>
          </div>}
        />}
        { (sharePDFUrl && sharePDFUrl != '') && <Popup
          title={'Your PDF is ready:'}
          component={<div className={style.shareModal}>
            <Link href={sharePDFUrl} target='_blank'>{sharePDFUrl}</Link>
            <button className={style.loadMoreBtn} onClick={() => {
              if (navigator.clipboard) {
                navigator.clipboard.writeText(sharePDFUrl).catch((error) => {
                  console.error('Unable to copy text:', error);
                });
              } else {
                const input = document.createElement('textarea');
                input.value = sharePDFUrl;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
              }
            }}>Copy URL</button>
          </div>}
          closePopup={() => setSharePDFUrl('')}
        /> }
        <div className={style.section} id='answerContainer'>
          <Input
            label='Habit'
            value={`H(${most_recent_entry.question.tuhr_habit}, ${most_recent_entry.question.hayd_habit}, ${most_recent_entry.question.nifaas_habit})`}
            disabled={true}
            otherStyle={style.input}
          />
          { most_recent_entry.question?.events && most_recent_entry.question.events.length > 0 && <CheckboxWithText
            text1={<img src={SequenceIcon} alt="SequenceIcon" width={24} height={24} /> }
            text2={<img src={CalendarIcon} alt="CalendarIcon" width={24} height={24} />}
            selected={dataMode === 'calendar' ? 2 : 1}
            action={() => setDataMode((e) => e === 'sequence' ? 'calendar' : 'sequence')}
            otherStyle={style.checkboxWithText}
          />}
          { dataMode === 'sequence' ? <Input
            label='Sequence'
            component={<p className={style.sequenceTextContainer}>{most_recent_entry.sequence.split(' ').map((txt, i) => {
              const insideTxt = txt.split('[');
              const classColor = getColor(insideTxt[0]);
              const secondTxt =  insideTxt[1] && `${insideTxt[1].slice(0, -1)}`.split('-');
              return (
                <Fragment key={`sequence_text_${txt}_${i}`}>
                  <span className={`${classColor} ${style.coloredSequenceText}`}>{insideTxt[0]}</span>
                  { secondTxt && <span className={style.bracket}>{'['}</span>}
                  { secondTxt && <span className={style.smallSequenceText}>{`${secondTxt[0]}`}<br />{secondTxt[1]}</span>}
                  { secondTxt && <span className={style.bracket}>{']'}</span>}
                </Fragment>
            )})}</p>}
            disabled={true}
            otherStyle={`${generatingPDF ? style.pdfInput : ''} ${style.input} ${style.sequenceHeight}`}
          /> : <div className={style.events}>
            {most_recent_entry.question.events.map((event, i) => {
              return (
                <div key={`event_answer_${i}_${event.from}_${event.to}`} className={style.eventContainer}>
                  <p>{i+1}. </p>
                  <Input
                    label={startingLabel(event.type)}
                    value={formatDate(new Date(event.from * 1000))}
                    type='text'
                    disabled={true}
                    otherStyle={`${style.input} ${style.eventInput} ${event.type === 'pregnancy' ? style.pregnancyEvent : event.type === 'baby' ? style.babyEvent : ''}`}
                  />
                  { isBleeding(event.type) && <Input
                    label='End'
                    value={formatDate(new Date(event.to * 1000))}
                    type='text'
                    disabled={true}
                    otherStyle={`${style.input} ${style.eventInput}`}
                  />}
                </div>
              )
            })}
          </div>}
          <Input
            label='Answer'
            component={<p className={style.sequenceTextContainer}>{most_recent_entry.answer.split(' ').map((txt, i) => {
              const insideTxt = txt.split('[');
              const classColor = getColor(insideTxt[0]);
              const secondTxt =  insideTxt[1] && `${insideTxt[1].slice(0, -1)}`.split('-');
              return (
                <Fragment key={`sequence_text_${txt}_${i}`}>
                  <span className={`${classColor} ${style.coloredSequenceText}`}>{insideTxt[0]}</span>
                  { secondTxt && <span className={style.bracket}>{'['}</span>}
                  { secondTxt && <span className={style.smallSequenceText}>{`${secondTxt[0]}`}<br />{secondTxt[1]}</span>}
                  { secondTxt && <span className={style.bracket}>{']'}</span>}
                </Fragment>
            )})}</p>}
            disabled={true}
            otherStyle={`${generatingPDF ? style.pdfInput : ''} ${style.input} ${style.sequenceHeight}`}
          />
          { ( most_recent_entry.question?.events && most_recent_entry.question.events?.length > 0 && most_recent_entry?.answer_breakdown && most_recent_entry.answer_breakdown?.length > 0) && <div className={style.answerwBreakDown}>
            <p className={style.sectionTitle}>Answer breakdown:</p>
            { most_recent_entry.answer_breakdown.map((ans, i) => {
              const classColor = getColor(ans.ruling);
              return <p key={`${ans.ruling}_${ans.duration}_${i}`} className={`${style.sequenceTextContainer} ${style.largeFont}`}>
                <span className={style.bracket}>{'['}</span>
                <span className={style.smallSequenceText}>{`${formatBreakDownDate(new Date((ans.interval.from) * 1000 ))}`} <br /> {`${formatBreakDownDate(new Date((ans.interval.until) * 1000 ))}`}</span>
                <span className={style.bracket}>{']'}</span>
                {ans.duration} <span className={`${classColor} ${style.coloredSequenceText}`}>{ans.ruling}</span>
              </p>;
            }) }
          </div> }
        </div>
      </div>}
      {graphDefinition && <div>
        <RenderGraph graphDefinition={graphDefinition} />
      </div>}
      {/* History */}
      {history.length > 0 && (
        <div>
          <p className={style.sectionTitle}>History section:</p>
          <div className={`${style.section} ${style.historySection}`}>
            {history.slice(0, numberOfVisibleHistoryItems).map((entry, index) => (
              <button key={index} onClick={() => setSelectedEntry(entry)}>
                <Input
                  label='Sequence'
                  component={<p className={style.sequenceTextContainer}>
                    {`H(${entry.question.tuhr_habit}, ${entry.question.hayd_habit}, ${entry.question.nifaas_habit})`}
                    {entry.sequence && entry.sequence.split(' ').map((txt, i) => {
                      const insideTxt = txt.split('[');
                      const classColor = getColor(insideTxt[0]);
                      const secondTxt =  insideTxt[1] && `${insideTxt[1].slice(0, -1)}`.split('-');
                      return (
                        <Fragment key={`sequence_text_${txt}_${i}`}>
                          <span className={`${classColor} ${style.coloredSequenceText}`}>{insideTxt[0]}</span>
                          { secondTxt && <span className={style.bracket}>{'['}</span>}
                          { secondTxt && <span className={style.smallSequenceText}>{`${secondTxt[0]}`}<br />{secondTxt[1]}</span>}
                          { secondTxt && <span className={style.bracket}>{']'}</span>}
                        </Fragment>
                    )})}
                    =>
                    {entry.answer.split(' ').map((txt, i) => {
                      const insideTxt = txt.split('[');
                      const classColor = getColor(insideTxt[0]);
                      const secondTxt =  insideTxt[1] && `${insideTxt[1].slice(0, -1)}`.split('-');
                      return (
                        <Fragment key={`sequence_text_${txt}_${i}`}>
                          <span className={`${classColor} ${style.coloredSequenceText}`}>{insideTxt[0]}</span>
                          { secondTxt && <span className={style.bracket}>{'['}</span>}
                          { secondTxt && <span className={style.smallSequenceText}>{`${secondTxt[0]}`}<br />{secondTxt[1]}</span>}
                          { secondTxt && <span className={style.bracket}>{']'}</span>}
                        </Fragment>
                    )})}
                  </p>}
                  disabled={true}
                  otherStyle={`${style.input} ${style.sequenceHeight}`}
                  id={`history_item_${index}`}
                />
              </button>
            ))}
            { (history?.length > 3 && numberOfVisibleHistoryItems < history.length) && <button className={style.loadMoreBtn} onClick={handleLoadMore}>Load More</button>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Answer;
