import LoadingSpinner from '@components/LoadingSpinner';
import style from './style.css';

const LoadingSplashScreen = () => {
  return (
    <div className={style.splash}>
      <LoadingSpinner
        otherStyle={style.fixLoadingSpinner}
      />
      <h1>myhayd<span className={style.rectangle} />app</h1>
    </div>
  );
};

export default LoadingSplashScreen;
