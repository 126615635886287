// src/components/QA/index.js
import { useState, useCallback } from 'preact/hooks';
import Question from '@components/Question';
import Answer from '@components/Answer';
import CalculatorApi from '@components/CalculatorApi';
import ErrorPopup from '@components/ErrorPopup';

const QA = ({ calculationMode, showResults, setShowResults }) => {
  const [isValidationEnabled, setValidationEnabled] = useState(true);
  const [input, setInput] = useState({
    sequence: "",
    hayd_habit: null, // was there any reason to set a value for hayd and tuhr ?
    tuhr_habit: null,
    nifaas_habit: null,
    events: null,
  });

  const [history, setHistory] = useState([]);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false)

  const handleSubmit = useCallback(
    () => {
      setSubmit(true);
    },
    [setSubmit]
  );

  const toggleValidation = useCallback(() => {
    if (isValidationEnabled) {
      alert('This will turn off validations on your input. The purpose of validations is to serve as training wheels to assist you in learning and understanding how to input correct sequences. If you believe that you have properly mastered this, then turning off validations can be beneficial when you want to test sequences and edge cases that currently the validations may flag as invalid whereas they do represent sensible use-cases.');
    }
    setValidationEnabled(prev => !prev);
  }, [isValidationEnabled]);

  const closeErrorPopup = () => {
    setError(false);
    setSubmit(false)
  };

  const randomInRange = ({ from, until, subset: { from: subsetFrom, until: subsetUntil, probability } }) => {
    const randomNumber = Math.random();

    if (randomNumber < probability) {
      // Generate a random number between `from` and `until` (inclusive)
      return Math.floor(Math.random() * (until - from + 1)) + from;
    }

    // Generate a random number between `subsetFrom` and `subsetUntil` (inclusive), excluding the `from`-`until` range
    let result;
    do {
      result = Math.floor(Math.random() * (subsetUntil - subsetFrom + 1)) + subsetFrom;
    } while (result >= from && result <= until);

    return result;
  };

  const randomBTSequence = () => {
    const length = Math.floor(Math.random() * 5) + 1;
    const sequence = [];

    for (let i = 0; i < length; i++) {
      const randomTuhr = () => randomInRange({ from: 15, until: 25, subset: { from: 1, until: 35, probability: 0.75 } });
      const randomBleeding = () => randomInRange({ from: 3, until: 10, subset: { from: 1, until: 20, probability: 0.75 } });
      sequence.push(`${randomBleeding()}B`, `${randomTuhr()}T`);
    }

    return sequence.join(' ');
  };

  const randomPregnancySequence = () => {
    const sequence = [];
    sequence.push('PS');  // Pregnancy Start

    let totalDuration = 0;
    let btSequence = '';

    // Determine if we should add a randomBTSequence in between with 10% probability
    if (Math.random() < 0.1) {
      const MAX_ITERATIONS = 20;  // safety limit
      let iterations = 0;

      while (totalDuration < 180 && iterations < MAX_ITERATIONS) {
        const proposedSequence = randomBTSequence();
        const matches = proposedSequence.match(/\d+/g);
        const proposedDuration = matches ? matches.map(Number).reduce((a, b) => a + b, 0) : 0;

        // Only add if it doesn't exceed 180
        if (totalDuration + proposedDuration <= 180) {
          btSequence += `${proposedSequence} `;
          totalDuration += proposedDuration;
        }

        iterations++;
      }

      sequence.push(btSequence.trim());
    }

    // Add remaining Tuhr days while keeping the total duration in mind
    sequence.push(`${randomInRange({
      from: 180 - totalDuration,
      until: 280 - totalDuration,
      subset: {
        from: 180 - totalDuration,
        until: 280 - totalDuration,
        probability: 1 // Make it always choose from this range
      }
    })}T`);

    // Randomly decide if the sequence will include a BB
    if (Math.random() > 0.5) {
      sequence.push('BB');
    }

    // console.log('sequence', sequence)
    return sequence.join(' ');
  };

  const randomEvents = ( n = 2 ) => {
    let startDate = new Date();
    let endDate = new Date();
    let dateRanges = [];

    for (let i = 0; i < n; i++) {
        startDate = new Date(endDate.getTime() + Math.floor(Math.random() * 20 + 15) *24*60*60*1000); // Start the next day after the last end date with 15~20 days gap
        endDate = new Date(startDate.getTime() + Math.floor(Math.random() * 10 + 1) * 24*60*60*1000); // End date is random number of days after start date, up to 10 days

        dateRanges.push({
            from: Math.floor(startDate.setHours(0,0,0,0) / 1000),
            to: Math.floor(endDate.setHours(0,0,0,0) / 1000),
            type: 'bleeding',
        });
    }

    return dateRanges;
  };

  const randomizeInput = () => {
    return {
      sequence: randomBTSequence(),
      hayd_habit: Math.floor(Math.random() * 8) + 3,
      tuhr_habit: Math.floor(Math.random() * (35 - 15 + 1)) + 15,
      nifaas_habit: Math.floor(Math.random() * 41),
      events: randomEvents(Math.floor(Math.random() * 4) + 1),
    };
  };

  const randomizeInputExtensively = () => {
    const sequence = [];

    // Randomly decide if the sequence will start with a BT sequence
    if (Math.random() > 0.5) {
      sequence.push(randomBTSequence());
    }

    // Add the pregnancy sequence
    sequence.push(randomPregnancySequence());

    // Randomly decide if the sequence will end with a BT sequence
    if (Math.random() > 0.2) {
      sequence.push(randomBTSequence());
    }

    return {
      sequence: sequence.join(' '),
      hayd_habit: Math.floor(Math.random() * 8) + 3,
      tuhr_habit: Math.floor(Math.random() * (35 - 15 + 1)) + 15,
      nifaas_habit: Math.floor(Math.random() * 41),
      events: randomEvents(Math.floor(Math.random() * 7) + 4),
    };
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      { !showResults && <Question
        calculationMode={calculationMode}
        input={input}
        setInput={setInput}
        handleSubmit={handleSubmit}
        randomizeInput={randomizeInput}
        randomizeInputExtensively={randomizeInputExtensively}
        isValidationEnabled={isValidationEnabled}
        toggleValidation={toggleValidation}
      /> }
      { showResults && <Answer history={history} setError={setError} setInput={setInput} setSubmit={setSubmit} setShowResults={setShowResults} />}
      {submit && <CalculatorApi
        calculationMode={calculationMode}
        input={input}
        setInput={setInput}
        history={history}
        setHistory={setHistory}
        setError={setError}
        setSubmit={setSubmit}
        setShowResults={setShowResults}
        isValidationEnabled={isValidationEnabled}
      />}
      {error && <ErrorPopup message={error} closeErrorPopup={closeErrorPopup} />}
    </div>
  );
};

export default QA;
