import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

const LoadingContext = createContext();
const useLoading = () => useContext(LoadingContext);
const LoadingProvider = ({ value, children }) => {
  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
};

export { useLoading, LoadingProvider };
