import style from './style.css';

const Checkbox = ({ checked, action }) => {
  return (
    <button
        onClick={action}
        className={style.checkbox}
    >
      <span className={`${style.circle} ${checked && style.active}`} style={{ right: checked ? '4px' : '31px' }} />
    </button>
  );
}
 
export default Checkbox;