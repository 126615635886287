import style from './style.css';
import useDeviceType from '@hooks/useDeviceType';
import Logo from '@assets/logo.png';

const Header = ({ btnAction, calculationMode, showResults }) => {

  const device = useDeviceType();
	
	return (
    <header className={style.header}>
      <div className={style.container}>
        <a href="/" className={style.logo} id='logo'>
          <img className={style.logoImg} src={Logo} alt="MyHayd Calculator" height="40" width="40" />
          <h1 className={style.title}>MyHayd Calculator</h1>
        </a>
        <button
          onClick={btnAction}
          className={style.btn}
        >{ showResults ? ( device == 'mobile' ? `C` :  `Calculate another one`) : ( (device == 'mobile' || device == 'small-tablet') ? `?` : calculationMode == 'notation' ? `Show Notation Guide` : `Show Calendar Guide`)}</button>
      </div>
    </header>
	)
};

export default Header;
