import { h } from 'preact';
import Header from '@components/Header';
import NotationGuide from '@components/NotationGuide';
import { useState, useEffect } from 'preact/hooks';
import MainSwitch from '@components/MainSwitch';
import MainModal from '@components/MainModal';
import LoadingSplashScreen from '@components/LoadingSplashScreen';
import { LoadingProvider } from '@contexts/loading_context';


const App = () => {
  const [ showNotationGuide, setShowNotationGuide ] = useState(false);
  const [ showResults, setShowResults ] = useState(false);
  const [ calculationMode, setCalculationMode ] = useState('notation');
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if ( 'serviceWorker' in navigator ) { // check if browser supports serviceWorker
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.addEventListener('updatefound', () => {
            const installingWorker = registration.installing;
            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed') {
                console.log('New content is available; please refresh.');
                location.reload();
                localStorage.clear();
              }
            });
          });
        })
      });
    } else {
      console.error('The browser does not support serviceWorker!');
    }
  }, []);

  return (
    <div>
      <LoadingProvider value={setLoading}>
        { loading && <LoadingSplashScreen />}
        { showNotationGuide && <NotationGuide action={() => setShowNotationGuide(false)} />}
        <Header
          calculationMode={calculationMode}
          showResults={showResults}
          btnAction={() => {
            if ( showResults ) {
              setShowResults(false);
              return;
            }
            // we can change it to a guide only and send the mode to decided notation/calendar
            setShowNotationGuide(true);
          }}
        />
        <MainSwitch
          setCalculationMode={setCalculationMode}
          calculationMode={calculationMode}
          showResults={showResults}
          setShowResults={setShowResults}
        />
        <MainModal
          setCalculationMode={setCalculationMode}
          calculationMode={calculationMode}
          showResults={showResults}
          setShowResults={setShowResults}
        />
      </LoadingProvider>
    </div>
  );
}

export default App;
