import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const creatingPDF = ({ onSuccess, onError, setGeneratingPDF, setLoading, pdfData }) => {
  setGeneratingPDF(true);
  setLoading(true);
  const answerContainer = document.getElementById('answerContainer');
  const logo = document.getElementById('logo');
  const graph = document.getElementById('graph');

  // Set the PDF options and dimensions
  const options = {
    scale: 1.5, // to increase the resolution (from the default 96dpi)
    allowTaint: true,
    windowHeight: 1080,
    windowWidth: 1920,
  };

  try {
    const doc = new jsPDF('p', 'px', 'a4', true);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    const maxPageHeight = doc.internal.pageSize.height;
    let currentY = 0;
  
    // Convert HTML to canvas and then to PDF
    // Add logo and timestamp
    html2canvas(logo).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      currentY += 40;
      doc.addImage(imgData, 'PNG', 20, 5, 100, 30);
      // time
      doc.setFontSize(10);
      doc.text(new Date().toUTCString(), pdfWidth - 125, 20);
      // Title
      doc.setFontSize(12);
      doc.setFont(undefined, 'bold');
      doc.text(pdfData?.title || '', 20, currentY+5);
      doc.setFont(undefined, 'normal');
      doc.setFontSize(10);
      // Fatwa URL
      if ( pdfData?.fatwa ) {
        doc.setDrawColor(0);
        doc.setFillColor(97, 67, 133);
        doc.roundedRect(pdfWidth - 83, currentY-8, 50, 12, 5, 5, "F");
        doc.setTextColor(255, 255, 255);
        doc.textWithLink('Open fatwa', pdfWidth - 78, currentY, {url: pdfData.fatwa});
      }
      currentY += 20;
      // Accepted or Rejected
      doc.setDrawColor(0);
      if ( pdfData?.accepted ) {
        doc.setFillColor(128, 202, 169);
      } else {
        doc.setFillColor(230, 73, 38);
      }
      doc.roundedRect(20, currentY-8, 50, 12, 5, 5, "F");
      doc.setTextColor(255, 255, 255);
      doc.text(pdfData?.accepted ? 'Accepted' : 'Rejected', 28, currentY);
      doc.setTextColor(0, 0, 0);
      // Note
      if ( pdfData?.note ) {
        doc.text(pdfData.note, 80, currentY);
      }
      currentY += 20;
      // Add answerContainer
      html2canvas(answerContainer, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imageHeight = canvas.height;
        const imageWidth = canvas.width;
        const ratio = Math.min(pdfWidth / imageWidth, (pdfHeight - currentY) / imageHeight);
        const imgX = (pdfWidth - imageWidth * ratio) / 2;
        doc.addImage(imgData, 'PNG', imgX + 20, currentY, (imageWidth * ratio) - 40, imageHeight * ratio);
        currentY += (imageHeight * ratio);
        // Add graph
        html2canvas(graph, {...options, scale: 2}).then((canvas) => { // Increase it's quality a bit to not have a blured image
          const imgData = canvas.toDataURL('image/png');
          const imageHeight = canvas.height;
          const imageWidth = canvas.width;
          const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
          const imgX = (pdfWidth - imageWidth * ratio) / 2;
  
          // Calculate the remaining space on the page
          const remainingPageSpace = maxPageHeight - currentY;
          if (remainingPageSpace < (imageHeight * ratio)) {
            // Page is full, create a new page
            doc.addPage();
            currentY = 0;
          }
          doc.addImage(imgData, 'PNG', imgX, currentY, imageWidth * ratio, imageHeight * ratio);
          onSuccess(doc);
        }).catch(() => {
          onError();
        })
      }).catch(() => {
        onError();
      });
    }).catch(() => {
      onError();
    });
  } catch (err) {
    onError();
  }
};

export const downloadPDF = async ({ setGeneratingPDF, pdfData, setLoading, setError }) => {

  const onError = () => {
    setGeneratingPDF(false);
    setLoading(false);
    setError('Something went wrong while trying to generate the PDF, kindly refresh the page and try again.');
  };

  creatingPDF({ onSuccess: (doc) => {
    doc.save('myHaydCalculator');
    setGeneratingPDF(false);
    setLoading(false);
  }, onError, setGeneratingPDF, setLoading, pdfData })

} 

export const sharePDF = async ({ setGeneratingPDF, pdfData, setLoading, setError, onSuccess }) => {

  const onError = () => {
    setGeneratingPDF(false);
    setLoading(false);
    setError('Something went wrong while trying to generate the PDF url, kindly refresh the page and try again.');
  };

  creatingPDF({ onSuccess: (doc) => {
    const pdfBlob = doc.output('blob'); // there are other types as well
    // Possible values are 'arraybuffer', 'blob', 'bloburi'/'bloburl', 'datauristring'/'dataurlstring', 'datauri'/'dataurl', 'dataurlnewwindow', 'pdfobjectnewwindow', 'pdfjsnewwindow'
    const formData = new FormData();
    formData.append('pdf', pdfBlob, 'myHaydCalculator.pdf');

    fetch('/upload-pdf', {
      method: 'POST',
      body: formData,
    }).then((res) => res.json()).then((data) => {
      console.log('PDF url', data);
      onSuccess(data);
      setGeneratingPDF(false);
      setLoading(false);
    }).catch((err) => {
      console.log('err', err);
      onError();
    });
  }, onError, setGeneratingPDF, setLoading, pdfData })

} 