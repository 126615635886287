import style from './style.css';

const NotationGuide = ({ action }) => {

  return (
    <div className={style.notationGuideContainer} onClick={action}>
      <div className={style.notationGuide} onClick={(e) => e.stopPropagation()}>
        <button onClick={action} className={style.closeBtn} />
        <div className={style.notationGuideContent}>
          <p>
            Sequence format: <strong>3B 13T 12B</strong>
          </p>
          <p>
            <b>B</b>: Bleeding days
            <br />
            <b>T</b>: Tuhr (no bleeding) days
            <br />
            <b>PS</b>: Pregnancy Started.
            <br />
            <b>BB</b>: Baby Birthed. (At this point, majority of the baby has been birthed so nifaas will start to apply)
            <br />
            <br />
            <u>Examples:</u> <br />
            <b>PS 280T BB 30B 15T</b> -> After 280 days of pregnancy, she gave birth and experienced 30 days of nifaas followed by a tuhr of 15 days <br />
            <b>3B 13T 12B PS 280T BB 30B 15T</b> -> Same as above, except that before pregnancy there is the sequence: <strong>3B 13T 12B</strong> that applies <br />

            <br />
            <br />
            <u>Buttons:</u> <br />
            <b>Calculate</b> -> Submits the given sequence, together with habit to the backend to calculate accordingly <br />
            <b>Randomize</b> -> Randomizes some inputs, in terms of habit/sequence to make it easier to see a variety of examples <br />
            <b>Randomize (extensively)</b> -> Same as above, but in addition to B and T symbols, it will also incorporate PS and BB symbols <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotationGuide;
