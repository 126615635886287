import style from './style.css';
import Events from '@components/Events';
import Checkbox from '@components/Checkbox';
import Input from '@components/Input';
import { useEffect, useRef, useState } from 'preact/hooks';
import useDeviceType from '@hooks/useDeviceType';

const Question = ({ calculationMode, input, setInput, handleSubmit, randomizeInput, randomizeInputExtensively, isValidationEnabled, toggleValidation }) => {
  const questionContainerRef = useRef();
  const [eventsHeight, setEventsHeight] = useState(0);
  const device = useDeviceType();

  useEffect(() => {
    const handleResize = () => {
      if ( questionContainerRef?.current ) {
        setEventsHeight(questionContainerRef.current.clientHeight - ( device == 'small-tablet'  || device === 'tablet' ? 250 : 165 ));
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionContainerRef, questionContainerRef?.current]);
  const handleButtonClick = () => {
    handleSubmit(input);
  };

  const handleChange = (e, key) => {
    setInput({ ...input, [key]: key === 'sequence' ? e.target.value : key === 'events' ? e : parseInt(e.target.value, 10) });
  };

  const handleRandomize = () => {
    setInput(randomizeInput());
  };

  const handleRandomizeExtensively = () => {
    setInput(randomizeInputExtensively());
  };

  return (
    <div className={style.questionContainer} ref={questionContainerRef}>
      <div>
        <div className={style.randomizeBtnsContainer}>
          <button onClick={handleRandomize}>Randomize</button>
          <button onClick={handleRandomizeExtensively}>Randomize (extensively)</button>
        </div>
        <div className={style.habitsContainer}>
          <Input
            placeholder='15~1000'
            label='Tuhr'
            type='number'
            min={15}
            max={1000}
            action={(e) => handleChange(e, "tuhr_habit")}
            value={input.tuhr_habit}
          />
          <Input
            placeholder='3~10'
            label='Hayd'
            type='number'
            min={3}
            max={10}
            action={(e) => handleChange(e, "hayd_habit")}
            value={input.hayd_habit}
          />
          <Input
            placeholder='0~40'
            label='Nifaas'
            type='number'
            min={0}
            max={40}
            action={(e) => handleChange(e, "nifaas_habit")}
            value={input.nifaas_habit}
          />
        </div>
        { calculationMode == 'notation' ? <Input
            placeholder='e.g. "3B 13T 12B"'
            label='Sequence'
            action={(e) => handleChange(e, "sequence")}
            otherStyle={style.sequenceInput}
            value={input.sequence}
          /> : <Events
                eventsHeight={eventsHeight}
                action={(e) => handleChange(e, "events")}
                input={input}
          />}
      </div>
      <div className={style.flexColumn}>
        { calculationMode == 'notation' && <div className={style.validation}>
          <p>Validation</p>
          <Checkbox checked={isValidationEnabled} action={toggleValidation} />
        </div>}

        <button onClick={handleButtonClick} className={style.calculateBtn}>Calculate</button>
      </div>
    </div>
  );
};

export default Question;
