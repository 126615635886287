import { useEffect, useState } from 'preact/hooks';

const getDevice = () => {
  const { innerWidth: width } = window;

  if ( width > 992) {
    return 'desktop'; // any large screen
  } else if ( width > 480 && width < 550 ) {
    return 'small-tablet'; // large mobile or small tablet
  } else if ( width > 480) {
    return 'tablet';
  }
  return 'mobile';
};

/**
 * The hook gets the width of the screen
 * to know which device the user had
 * @return {String} the type of device
 */
export default function useDeviceType() {
  const [device, setDevice] = useState(getDevice());

  useEffect( () => {
    const handleResizing = () => {
      setDevice(getDevice());
    };

    window.addEventListener('resize', handleResizing);

    return () => window.removeEventListener('resize', handleResizing);
  }, []);

  return device;
}