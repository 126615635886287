import style from './style.css';

const Input = ({ id, otherStyle, label, placeholder, type, min, max, value, disabled, action, component }) => {
  return (
    <div className={`${style.input} ${otherStyle || '' }`}>
      <label htmlFor={label} className={style.label}>{label}</label>
      { component ? <div className={`${style.inputField} ${style.component}`}>{component}</div> : <input
        type={type || 'text'} 
        id={id || label} 
        placeholder={placeholder} 
        className={style.inputField} 
        onInput={action}
        min={min}
        max={max}
        value={value}
        disabled={disabled}
      />}
    </div>
  );
}
 
export default Input;