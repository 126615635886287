import { useEffect, useState } from 'preact/hooks';
import style from './style.css';
import useDeviceType from '@hooks/useDeviceType';

const MainSwitch = ({ setCalculationMode, calculationMode, showResults, setShowResults }) => {

  const [ firstBtnIsSeleceted, setFirstBtnIsSeleceted ] = useState(calculationMode == 'notation' && !showResults);
  const device = useDeviceType();
  const action = () => {
    if ( showResults ) {
      setShowResults(false);
    } else {
      setCalculationMode((calculationMode != 'notation') ? 'notation' : 'calendar' );
    }
    setFirstBtnIsSeleceted((prev) => !prev);
  };

  useEffect(() => {
    setFirstBtnIsSeleceted(!(showResults || calculationMode == 'calendar'));
  }, [showResults, calculationMode]);

  return (
    <div className={style.switchContainer}>
      <span className={`${style.selectionLayer}`} style={{ left: ( device == 'mobile' || device == 'small-tablet' ) ? (firstBtnIsSeleceted ? '10px' : '160px') : (firstBtnIsSeleceted ? '10px' : '259px') }} />
      <button
        className={ firstBtnIsSeleceted ? style.selectedBtn : style.notSelectedBtn }
        onClick={action}
      >{ showResults ? `Calculation` : `Notational Mode`}</button>
      <button
        className={ !firstBtnIsSeleceted ? style.selectedBtn : style.notSelectedBtn }
        onClick={action}
      >{ showResults ? `Results` : `Calendar Mode`}</button>
    </div>
  );
}
 
export default MainSwitch;