import { useEffect, useState } from 'preact/hooks';
import style from './style.css';

const MultiCheckbox = ({ elements, otherStyle, action, selectedTypeIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedTypeIndex || 0);
  
  useEffect(() => {
    if ( elements && selectedTypeIndex != null && selectedTypeIndex !== selectedIndex ) {
      setSelectedIndex(selectedTypeIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeIndex]);

  if ( !elements || elements.length < 1 ) {
    console.log('[MutliCheckbox] there is no elements', elements);
    return;
  }

  return (
    <div className={`${style.multiCheckbox} ${otherStyle ? otherStyle : ''}`} style={{ width: `${elements.length * 38}px` }}>
      <span className={`${style.selectionLayer}`} style={{ left: `${2 + (selectedIndex*37)}px` }} />
      { elements.map((el, i) => (
        <button
          key={`multiCheckbox_${el.type}_${i}`}
          className={ i === selectedIndex ? style.selectedBtn : ''}
          title={el.title}
          onClick={() => {
            setSelectedIndex(i);
            action(el.type);
          }}
        >{el.component}</button>
      ))}
    </div>
  );
}
 
export default MultiCheckbox;