
// src/components/ErrorPopup/index.js
import style from './style.css';

const ErrorPopup = ({ message, closeErrorPopup }) => {
  return (
    <div className={style["error-popup"]}>
      <div className={style["error-popup-content"]}>
        <h2>Error</h2>
        {message?.includes('<br/>') ? <div>
          { message.split('<br/>').map((err) => (
            <p key={`error_popup_err_${err}`}>{err}</p>
          )) }
        </div> : <p>{message}</p>}
        <div className={style.btnsContainer}>
          <button className={style.btn} onClick={() => {
            localStorage.clear();
            location.reload();
          }}>Clear old data</button>
          <button className={style.btn} onClick={closeErrorPopup}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
