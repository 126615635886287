import { useState, useEffect, useRef } from "preact/hooks";
import style from './style.css';
import Input from '@components/Input';
import CheckboxWithText from '@components/CheckboxWithText';
import { isValidDate, dateToDateTimeString, dateTimeToTS, calculatedDurationFromSeconds } from "@utils/date";
import PregnancyIcon from '@assets/icons/pregnancy.png';
import BabyIcon from '@assets/icons/baby.png';
import BleedingIcon from '@assets/icons/period.png';
import MultiCheckbox from "@components/MutliCheckbox";

const bleedings = {bleeding: true, final_bleeding: true}
export const isBleeding = (type) => bleedings[type]
export const startingLabel = (type) => {
  let label;
  switch(type) {
    case 'bleeding':
      label = 'Start'
      break;
    case 'final_bleeding':
      label = 'Start'
      break;
    case 'pregnancy':
      label = 'Pregnancy Started'
      break;
    default:
      label = 'Baby Birthed'
      break;
  }
  return label
}

export const Event = ({ setData, eventNumber, event, onDelete, allowDeleting }) => {
  const [allowEndDate, setAllowEndDate] = useState(event?.allowEndDate || true);
  const [duration, setDuration] = useState({
    day: event?.to ? calculatedDurationFromSeconds(dateTimeToTS(new Date((event.to - event.from) * 1000).getTime())).days : 0,
    hour: event?.to ? calculatedDurationFromSeconds(dateTimeToTS(new Date((event.to - event.from) * 1000).getTime())).hours : 0,
    min: event?.to ? calculatedDurationFromSeconds(dateTimeToTS(new Date((event.to - event.from) * 1000).getTime())).mins : 0
  });
  const [startDate, setStartDate] = useState(new Date( event?.from ? event.from * 1000 : new Date().setHours(0,0,0,0)));
  const [type, setType] = useState(event?.type || 'final_bleeding');
  const [endDate, setEndDate] = useState(isBleeding(type) && new Date( event?.to ? event.to * 1000 : new Date().setHours(0,0,0,0)));
  const typesRef = useRef(['final_bleeding', 'bleeding', 'pregnancy', 'baby']);

  const setDurationFromSeconds = (seconds) => {
    const { days, hours, mins } = calculatedDurationFromSeconds(seconds);
    if ( days != duration.day || hours != duration.hour || mins != duration.min) {
      setDuration({
        day: days,
        hour: hours,
        min: mins,
      });
    }
  };

  useEffect(() => {
    console.log('endDate', endDate)
    if ( event?.from && event.from != dateTimeToTS(startDate.getTime()) ) {
      setStartDate(new Date(event.from * 1000));
    }
    if ( event?.to && event.to != dateTimeToTS(endDate.getTime()) ) {
      setEndDate(new Date(event.to * 1000));
      const seconds = event.to - event.from;
      setDurationFromSeconds(seconds);
    }
    if ( event?.allowEndDate && event.allowEndDate != allowEndDate ){
      setAllowEndDate(event.allowEndDate);
    }
    if ( event?.type && event.type != type ) {
      setType(event.type);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, event?.from, event?.to, event?.type, event?.allowEndDate]);

  useEffect(() => {
    if ( startDate && ( (allowEndDate && endDate) || (!allowEndDate) ) ) {
      let calculatedEndDate = endDate.getTime();
      if ( !allowEndDate ) {
        calculatedEndDate = startDate.getTime() + (((duration.day * 24 * 60 * 60)+(duration.hour * 60 * 60)+(duration.min * 60)) * 1000);
      }
      setData({
        from: dateTimeToTS(startDate.getTime()),
        // change the duration to day hour min
        to: isBleeding(type) && dateTimeToTS(calculatedEndDate),
        type,
        allowEndDate,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, duration, allowEndDate, type]);

  return (
    <div className={style.eventContainer}>
      <p>{eventNumber}.</p>
      <MultiCheckbox
        elements={[
          {
            component: <img src={BleedingIcon} alt="Final Bleeding" width={16} height={24.5} />,
            type: 'final_bleeding',
            title: 'Final Bleeding'
          },
          {
            component: <img src={BleedingIcon} alt="Ongoing Bleeding" width={16} height={24.5} />,
            type: 'bleeding',
            title: 'Ongoing Bleeding'
          },
          {
            component: <img src={PregnancyIcon} alt="Pregnancy" width={16} height={27.3} />,
            type: 'pregnancy',
            title: 'Pregnancy Started'
          },
          {
            component: <img src={BabyIcon} alt="Baby" width={17} height={29} />,
            type: 'baby',
            title: 'Baby Birthed'
          },
        ]}
        otherStyle={style.multiCheckbox}
        action={(e) => setType(e)}
        selectedTypeIndex={typesRef.current.indexOf(type)}
      />
      <Input
        label={startingLabel(type)}
        value={dateToDateTimeString(startDate)}
        action={(e) => {
          if ( isValidDate(new Date(e.target.value)) ) {
            setStartDate(new Date(new Date(e.target.value).setSeconds(0, 0)));
          }
        }}
        type='datetime-local'
        otherStyle={`${style.input} ${type === 'pregnancy' ? style.pregnancyInput : type === 'baby' ? style.babyInput : ''}`}
      />
      { !allowEndDate && <div className={style.durationInputContainer}>
        <label>End</label>
        <div className={style.inputsContainer}>
          <input min={0} max={10} type='number' name='day' value={duration.day} onChange={(e) => setDuration((prev) => {
            prev['day'] = e.target.value;
            return {...prev};
          })} />
          <p>{`day${!duration?.day || (duration.day > 1) ? 's' : ''}`}</p>
          <input min={0} max={23} type='number' name='hour' value={duration.hour} onChange={(e) => setDuration((prev) => {
            prev['hour'] = e.target.value;
            return {...prev};
          })} />
          <p>{`hour${!duration?.hour || (duration.hour > 1) ? 's' : ''}`}</p>
          <input min={0} max={59} type='number' name='min' value={duration.min} onChange={(e) => setDuration((prev) => {
            prev['min'] = e.target.value;
            return {...prev};
          })} />
          <p>{`min${!duration?.min || (duration.min > 1) ? 's' : ''}`}</p>
        </div>
      </div>}
      { (allowEndDate && isBleeding(type)) && <Input
        label='End'
        value={dateToDateTimeString(endDate)}
        action={(e) => {
          if ( isValidDate(new Date(e.target.value)) ) {
            setEndDate(new Date(new Date(e.target.value).setSeconds(0, 0)));
          }
        }}
        type='datetime-local'
        otherStyle={`${style.input} ${style.endDateInput}`}
      />}
      { isBleeding(type) && <CheckboxWithText
        text1={'Duration'}
        text2={'Until'}
        selected={allowEndDate ? 2 : 1}
        action={() => {
          if ( allowEndDate ) {
            const seconds = dateTimeToTS(endDate.getTime()) - dateTimeToTS(startDate.getTime());
            setDurationFromSeconds(seconds);
          }
          console.log('change allowEndDate state')
          setAllowEndDate(prev => !prev);
        }}
        otherStyle={style.checkboxWithText}
      />}
      <button className={style.closeBtn} onClick={() => onDelete(eventNumber)} disabled={!allowDeleting} />
    </div>
  );
}

