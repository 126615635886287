import style from './style.css';
import Logo from '@assets/logo-200px.webp';

const LoadingSpinner = ({ otherStyle }) => {
  return (
    <div className={`${style.loadingSpinner} ${otherStyle ? otherStyle : ''}`}>
      <img src={Logo} alt='logo' />
    </div>
  );
};

export default LoadingSpinner;
