export const isValidDate = (date) => date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);

export const dateToDateTimeString = (date) => new Date(`${new Date(date).toString().split('GMT')[0]} UTC`).toISOString().split('.')[0];

export const dateTimeToTS = (dateTime) => Math.floor(dateTime / 1000);

export const calculatedDurationFromSeconds = (seconds) => {
  let mins = Math.floor(seconds/60);
  let hours = Math.floor(mins/60);
  let days = seconds == 0 ? 0 : Math.floor(hours/24);
  hours = seconds == 0 ? 0 : hours-(days*24);
  mins = seconds == 0 ? 0 : mins-(days*24*60)-(hours*60);
  return {
    days,
    hours,
    mins,
  }
};

/*
  Since at the backend we expect UTC date, and the current date is in user local time (eg. GMT+2)
  so the server will subtract those 2 hours to convert it to UTC so instead we will increase extra
  2hours so when the server convert the time to UTC it will be the same as the user input time
*/
export const convertDateToUTCPlusOffset = (ts, timezoneOffset=new Date().getTimezoneOffset()) => {
    const utcDate = new Date((ts - (timezoneOffset * 60))  * 1000);
    return dateTimeToTS(utcDate.getTime());
};

/*
  As we need to handle the date returns from the backend again in answer_breakdown so we will need to subtract the offset time
*/
export const convertDateToUTCMinsOffset = (ts, timezoneOffset=new Date().getTimezoneOffset()) => {
    const utcDate = new Date((ts + (timezoneOffset * 60))  * 1000);
    return dateTimeToTS(utcDate.getTime());
};

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric', 
    minute: '2-digit', 
    hour12: true 
  }).format(date);
}

export const formatBreakDownDate = (date) => {
  const newDate = new Date(convertDateToUTCMinsOffset(dateTimeToTS(date.getTime())) * 1000);
  return new Intl.DateTimeFormat("en-US", {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(newDate)
}