
// src/components/ErrorPopup/index.js
import style from './style.css';

const Popup = ({ title, component, closePopup }) => {
  return (
    <div className={style["popup"]}>
      <div className={style["popup-content"]}>
        <h2>{title}</h2>
        { component && component }
        { closePopup && <div className={style.btnsContainer}>
          <button className={style.btn} onClick={closePopup}>Close</button>
        </div>}
      </div>
    </div>
  );
};

export default Popup;
